import React from 'react'
import { Col, Row } from 'react-bootstrap'
import profile from "./../../../../Image/person.png"
import wishlist from "./../../../../Image/Wishlist.png"
import address from "./../../../../Image/Address.png"
import order from "./../../../../Image/Details.png"
import Logowt from "./../../../../Image/Logowt.png"

import "./Main.css"
import { Link, Navigate } from 'react-router-dom'
import { useStateContext } from "../../../context/ContextProvider";
import axiosClient from "../../../../axios-client";


export default function Main() {
  const { customer, token, setCustomer, setToken, notification } = useStateContext();
  const onLogout = ev => {
    ev.preventDefault()

    axiosClient.post('/customer/logout')
      .then(() => {
        setCustomer({})
        setToken(null)
      })
  }

  return (
    <div>
      <div className='d-flex justify-content-between mt-4'>
        <span>دسترسی سریع</span>
        <span>سلام <span>{customer}</span>عزیز</span>
      </div>
      <Row style={{ justifyContent: "space-evenly" }} className='mt-2 mt-lg-5 gy-3'>
        <Col className='text-center' xs={4} lg={2}>
          <Link to={'details'}>
            <img className='img-fluid w-50' src={profile} alt="profile" />
            <div className='pt-2'>
              جزئیات حساب
            </div>
          </Link>
        </Col>
        <Col className='text-center' xs={4} lg={2}>
          <Link to={'orders'}>
            <img className='img-fluid w-50' src={order} alt="order" />
            <div className='pt-2'>
              سفارشات
            </div>
          </Link>
        </Col>
        <Col className='text-center' xs={4} lg={2}>
          <Link to={'wishlist'}>
            <img className='img-fluid w-50' src={wishlist} alt="wishlist" />
            <div className='pt-2'>
              علاقه مندی ها
            </div>
          </Link>
        </Col>
        <Col className='text-center' xs={4} lg={2}>
          <Link to={'address'}>
            <img className='img-fluid w-50' src={address} alt="address" />
            <div className='pt-2'>
              آدرس
            </div>
          </Link>
        </Col>
        <Col className='text-center' xs={4} lg={2}>
          <Link onClick={onLogout} >
            <img className='img-fluid w-50' src={Logowt} alt="Logowt" />
            <div className='pt-2'>
              خروج
            </div>
          </Link>
        </Col>
      </Row>
      <div className='mt-2 mt-lg-5 pt-lg-5'>
        <p className='account-tip text-muted'>
          از طریق پیشخوان حساب کاربری میتوانید سفارش های اخیرتان را مشاهده ادرس های حمل و نقل و صورتحسابتان را مدیریت و جزئیات حساب کاربری خود را ویرایش کنید
        </p>
      </div>
    </div >
  )
}
