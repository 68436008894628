import NavLinks from '../NavLinks/NavLinks'
import { Col, Container, Row, Accordion, ListGroup, Button, Offcanvas } from 'react-bootstrap'
import { Link, Outlet } from 'react-router-dom'
import Advertise from '../Advertise/Advetise'
import { FaAngleDown } from "react-icons/fa6";
import axiosClient from "../../axios-client";
import { useEffect, useState } from "react";



export default function ProductCategories() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [lastPage, setLastPage] = useState(1);
    const [mainCategories, setMainCategoeies] = useState([]);

    const link =[{
        name:'همه محصولات',
        link:'/product-category/all-products/',
    }];


    useEffect(() => {
        getMainCategory();
    }, []);

    const getMainCategory = () => {
        axiosClient.get('/getMainCategories')
            .then(({ data }) => {
                setMainCategoeies(data.data);
            })
            .catch((error) => {
                console.error('Error fetching main categories:', error);
            });
    };
    return (
        <>
            <NavLinks {...link} />

            <div className='d-lg-none'>
                <div className='text-center'>
                    <Button className='text-center fs-3' style={{ color: "gray" }} variant="" onClick={handleShow}>
                        دسته بندی محصولات
                        <FaAngleDown />

                    </Button>
                </div>

                <Offcanvas backdrop={false} placement='top' show={show} onHide={handleClose}>
                    <Offcanvas.Header className='pb-0' closeButton>
                        <Offcanvas.Title>دسته بندی محصولات</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='py-0'>
                        <div>
                            <Accordion defaultActiveKey="">
                                {mainCategories.map((mainCategory, index) => (
                                    <Accordion.Item className='header-accr my-3' eventKey={index} key={index}>
                                        <Link>
                                            <Accordion.Header>
                                                {mainCategory.name}
                                            </Accordion.Header>
                                        </Link>
                                        <Accordion.Body>
                                            <ListGroup className='ms-4' variant="flush">
                                                {mainCategory.product_category_id.map((productCategories, index) => (
                                                    <ListGroup.Item className='bg-transparent' key={index}><Link to={productCategories.slug}>{productCategories.name}</Link></ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}

                            </Accordion>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>

            <Container className='my-2 my-lg-5'>
                <Row>
                    <Col lg={3} className='order-2 order-lg-1'>
                        <div style={{ position: "sticky", top: "10px" }}>
                            <div className='d-none d-lg-block'>
                                <div>
                                    <h5>
                                        دسته بندی محصولات
                                    </h5>
                                </div>
                                <div className='mt-5'>
                                    <Accordion defaultActiveKey="">
                                        {mainCategories.map((mainCategory, index) => (

                                            <Accordion.Item className='header-accr my-3' eventKey={index} key={index}>
                                                {/* <Accordion.Header>{mainCategory.name}</Accordion.Header> */}
                                                <Accordion.Header>
                                                    <Link to={'/product-category/' + mainCategory.slug + '/'}>
                                                        {mainCategory.name}
                                                    </Link>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <ListGroup className='ms-4' variant="flush">
                                                        {mainCategory.product_category_id.map((productCategories, index) => (
                                                            <ListGroup.Item className='bg-transparent' key={index}><Link to={'/product-category/' + mainCategory.slug + '/' + productCategories.slug + '/'}>{productCategories.name}</Link></ListGroup.Item>
                                                        ))}

                                                    </ListGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}


                                    </Accordion>
                                </div>
                            </div>
                            <div className='mt-4 '>
                                <Advertise />
                            </div>
                        </div>
                    </Col>
                    <Col lg={9} className='order-1 order-lg-2'>
                        <Outlet />
                    </Col>
                </Row>
            </Container>
        </>
    )
}
