import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import axiosClient from "../../../../axios-client";

export default function DateTime({ setLevel }) {
  const [initialEvents, setInitialEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [times, setTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);

  useEffect(() => {
    const storedReservationId = localStorage.getItem('selectedReservationId');
    if (storedReservationId) {
      axiosClient.get(`/singleReservation/${storedReservationId}`)
        .then(({ data }) => {
          const weekdays = [
            { title: 'Sunday', display: 'background' },
            { title: 'Monday', display: 'background' },
            { title: 'Tuesday', display: 'background' },
            { title: 'Wednesday', display: 'background' },
            { title: 'Thursday', display: 'background' },
            { title: 'Friday', display: 'background' },
            { title: 'Saturday', display: 'background' }
          ];

          const availableDays = weekdays.filter(name => data.data[name.title.toLowerCase()]);
          setInitialEvents(availableDays);
        });
    }
  }, []);

  const generateRecurringEvents = (initialEvents, startYear, endYear) => {
    const dayMap = {
      'Saturday': 0,
      'Sunday': 1,
      'Monday': 2,
      'Tuesday': 3,
      'Wednesday': 4,
      'Thursday': 5,
      'Friday': 6,
    };

    const recurringEvents = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    initialEvents.forEach((event) => {
      const dayIndex = dayMap[event.title];
      if (dayIndex !== undefined) {
        for (let year = startYear; year <= endYear; year++) {
          let currentDate = new Date(year, 0, 1);

          while (currentDate.getDay() !== dayIndex) {
            currentDate.setDate(currentDate.getDate() + 1);
          }

          while (currentDate.getFullYear() === year) {
            if (currentDate >= today) {
              recurringEvents.push({
                start: currentDate.toISOString().split('T')[0],
                display: event.display || 'background',
                color: 'green',
              });
            }
            currentDate.setDate(currentDate.getDate() + 7);
          }
        }
      }
    });

    return recurringEvents;
  };

  useEffect(() => {
    if (initialEvents.length > 0) {
      const startYear = new Date().getFullYear();
      const endYear = startYear + 2;
      const allRecurringEvents = generateRecurringEvents(initialEvents, startYear, endYear);
      setEvents(allRecurringEvents);
    }
  }, [initialEvents]);

  const handleDateClick = (info) => {
    const clickedDate = info.dateStr;
    const dateObj = new Date(clickedDate);

    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayName = dayNames[dateObj.getDay()];

    const eventOnDate = events.find(event => event.start === clickedDate);

    if (eventOnDate) {
      const storedReservationId = localStorage.getItem('selectedReservationId');

      if (storedReservationId) {
        axiosClient.get(`/timeReservation/${storedReservationId}/${dayName}`)
          .then(({ data }) => {
            setTimes(data.data);
            setSelectedTime(null);
          });
      } else {
        setTimes([]);
        setSelectedTime(null);

      }
    } else {
      setTimes([]);
      setSelectedTime(null);

    }
  };

  const handleTimeClick = (time) => {
    setSelectedTime(time);
  };

  return (
    <div className=''>
      <div className='fs-4 py-2 mb-3' style={{ borderBottom: "solid 1px gray" }}>زمان و تاریخ</div>
      <div className='service-box'>
        <div className='row'>
          <div className='col-md-6'>
            <FullCalendar
              locale='fa'
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              direction="rtl"
              timeZone='Tehran,Iran'
              selectable={true}
              headerToolbar={{
                left: 'next',
                center: 'title',
                right: 'prev',
              }}
              events={events}
              dateClick={handleDateClick}
              validRange={{
                start: new Date().toISOString().split('T')[0],
              }}
            />
          </div>
          <div className='col-md-6'>
            <h3 className='text-center pb-4'>زمان</h3>
            <div className='row'>
              <hr />
              {times.map((time, index) => (
                <div
                  key={index}
                  className={`col-md-3 border text-center p-1 rounded ${selectedTime === time ? ' btn btn-success' : 'border-success'}`}
                  onClick={() => handleTimeClick(time)}
                  style={{ cursor: 'pointer' }}
                >
                  {time.time}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='fs-4 py-2 d-flex justify-content-between mt-3' style={{ borderTop: "solid 1px black" }}>
        <button onClick={() => setLevel(1)} className='res-prev-step btn btn-secondary text-white'>انتخاب سرویس</button>
        <button
          onClick={() => setLevel(3)}
          className={`res-next-step btn btn-secondary ${!selectedTime ? 'disabled' : ''}`}
          disabled={!selectedTime} 
          style={{
            pointerEvents: selectedTime ? 'auto' : 'none',
            opacity: selectedTime ? 1 : 0.5
          }}
        >
          ورود اطلاعات
        </button>
      </div>
    </div>
  );
}
