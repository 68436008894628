import "./Index.css"
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import NewestProducts from './component/NewestProducts/NewestProducts'
import Yadaki from './component/Yadaki/Yadaki'
import CustomerComments from './component/CustomerComments/CustomerComments'
import BlogSlider from './component/BlogSlider/BlogSlider'
import axiosClient from "../../axios-client";
import { useEffect, useState } from "react";
import menuIcon from "./../../Image/miniLine.png"
import arrows from "./../../Image/rows.png"
import banerTwo from "./../../Image/baner2.jpg"
import logo from "./../../Image/logo.png"
import dena from "./../../Image/dena.png"

export default function Index() {
  const apiKey = process.env.REACT_APP_API_KEY;
  const [loading, setLoading] = useState(false);
  const [mainCategory, setMainCategory] = useState([]);

  useEffect(() => {
    getMainCategory();
    document.title = 'فروشگاه اینترنتی آپگریدر';
  }, [])

  const getMainCategory = () => {
    setLoading(true)
    axiosClient.get('/getMainCategories')
      .then(({ data }) => {
        setLoading(false)
        setMainCategory(data.data)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <section className='section-one'>

        <Container fluid>
          <Row>
            <Col xs={6} className='p-0'>
              <div className='baner'>

              </div>
            </Col>
            <Col className='boxbaner' style={{ background: "white", }} xs={6}>
              <div className='title-baner-2'>مرجع تخصصی <br></br>تعمیر و تیونینگ خودرو های دارای <br></br>موتور EF7</div>
              <div className='title-baner-1 fs-0'>مجموعه تخصصی <span style={{ color: "#f6a414" }}>آپگریدر</span></div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='mt-5'>
        <Container fluid className='p-0 py-3 py-lg-5'>
          <div className='p-0 d-flex align-items-center'>
            <img className='arow' src={menuIcon} alt="" />
            <h5 className='ms-4 h2 blue sec-title'>دستبندی محصولات</h5>
          </div>
        </Container>
        <Container>
          <Row>
            {mainCategory.map((mainCategory, index) => (
              <Col xs={4} className='p-0 text-center' key={index}>
                <Link to={'/product-category/' + mainCategory.slug + '/'}>
                  {/* <h3 className="text-center">
                    {mainCategory.name}
                  </h3> */}
                  <img className='img-fluid' src={apiKey + mainCategory.photo} alt={mainCategory.name} />
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section>
        <Container fluid className='p-0 py-3 py-lg-5'>
          <div className='p-0 d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
              <img className='arow' src={menuIcon} alt="" />
              <h5 className='ms-4 blue sec-title'>جدیدترین محصولات</h5>
            </div>
            <div className='d-flex align-items-center '>
              <h5 className='ms-4 blue me-3 sec-title'>مشاهده بیشتر</h5>

              <img className='me-2 arow2' style={{ marginTop: "-7px" }} src={arrows} alt="" />
            </div>
          </div>
        </Container>
        <Container>
          <NewestProducts />
        </Container>

      </section>
      <section style={{ marginTop: "70px" }}>
        <div className=''>
          <Container fluid>
            <Row>
              <Col className='p-0 bg-light px-2 px-md-0 pb-3 pb-md-0' sm={4}>
                <div style={{ color: "#000052" }} className='mx-5 mt-5 mb-2 baner2-title'>تک به تک اسب ها</div>
                <div style={{ color: "#ff0101", textAlign: "end" }} className='baner2-title mx-5'>در اختیار توست</div>
                <div className='about-remap mx-lg-5 d-flex'>
                  <Link className='d-flex align-items-center'>
                    <span>درباره ریمپ</span>
                    <img src={arrows} className='mx-2' alt="" style={{ width: "25px" }} />
                  </Link>
                </div>
                <div className='time-remap mx-lg-5 d-flex'>
                  <Link className='d-flex align-items-center'>
                    <span>رزرو وقت ریمپ</span>
                    <img src={arrows} className='mx-2' alt="" style={{ width: "25px" }} />
                  </Link>
                </div>
              </Col>
              <Col className='p-0 bg-light' sm={8}><img src={banerTwo} className='img-fluid' alt="" />

              </Col>
            </Row>

          </Container>
        </div>
      </section>
      <section>
        <Container fluid className='p-0 py-3 mt-5 py-lg-5'>
          <div className='p-0 d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
              <img className='arow' src={menuIcon} alt="" />
              <h5 className='ms-4 blue sec-title'>لوازم یدکی</h5>
            </div>
            <div className='d-flex align-items-center '>
              <h5 className='ms-4 blue me-3 sec-title'>مشاهده بیشتر</h5>

              <img className='me-2 arow2' style={{ marginTop: "-7px" }} src={arrows} alt="" />
            </div>
          </div>
        </Container>
        <Container>
          <Yadaki />
        </Container>

      </section>
      <section>
        <Container fluid className='p-0 py-3 mt-5 py-lg-5'>
          <div className='p-0 d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
              <img className='arow' src={menuIcon} alt="" />
              <h5 className='ms-4 blue sec-title'>مشتریان ما</h5>
            </div>
          </div>
        </Container>
        <Container>
          <Row className='p-3 p-lg-0'>
            <Col xs={8} md={6} className='p-0 review-slider-box'>
              <CustomerComments />
            </Col>
            <Col xs={4} md={6} style={{ position: "relative" }} className='p-0 client-baner'>
              <div className='circle-box'>
                <img className='img-fluid' src={logo} alt="logo" />
              </div>
            </Col>
          </Row>
        </Container>

      </section>
      <section className='my-3'>
        <Container fluid className='p-0 py-5'>
          <div className='p-0 d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
              <img className='arow' src={menuIcon} alt="" />
              <h5 className='ms-4 blue sec-title'>خواندنی ها</h5>
            </div>
            <div className='d-flex align-items-center '>
              <h5 className='ms-4 blue me-3 sec-title'>مشاهده بیشتر</h5>

              <img className='me-2 arow2' style={{ marginTop: "-7px" }} src={arrows} alt="" />
            </div>
          </div>
        </Container>
        <Container className='blog-box p-3 p-lg-0'>
          <Row className=''>
            <Col xs={12} md={3} className=''><img src={dena} className='img-fluid blog-baner' alt="" /></Col>
            <Col xs={12} md={9} className='p-0 p-lg-4'>
              <BlogSlider />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
