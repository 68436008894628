import { Swiper, SwiperSlide } from 'swiper/react';
import SliderNavBtns from '../Index/component/NewestProducts/sliderNavBtns';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper/modules';
import { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import { PiShoppingCartSimple } from "react-icons/pi";
import { Link, useParams } from 'react-router-dom';

import Rating from '@mui/material/Rating';

export default function Slider2(props) {
    const [loading, setLoading] = useState(false);
    const apiKey = process.env.REACT_APP_API_KEY;
    const [value, setValue] = useState(3);

    const [newProduct, setNewProduct] = useState([]);
    const [ProductCategorySlug, setProductCategorySlug] = useState('');
    const [mainCategorySlug, setMainCategorySlug] = useState('');
    let { productSlug } = useParams();

    useEffect(() => {
        getYadaki();
    }, [])

    const getYadaki = () => {
        setLoading(true)
        axiosClient.get(`getRelated/${productSlug}`)
            .then(({ data }) => {
                setLoading(false)
                setNewProduct(data.data)
            })
            .catch(() => {
                setLoading(false)
            })
    }


    return (
        <Swiper
            loop={true}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            breakpoints={{
                350: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
        >
            {newProduct.map((pct, index) => (
                <SwiperSlide className='' key={index}>
                    <Link to={`/${'product/' + pct.slug}`}>
                        <div className='slidee slidee2'>
                            <div className='product-card product-card2'>
                                <div><img src={apiKey + pct.photo} className='img-fluid card-img' alt={pct.name} /></div>
                                <div className='mb-3 text-center mt-3'>{pct.name}</div>
                                <div className='text-center'>
                                    <Rating style={{ direction: "ltr" }}
                                        readOnly
                                        name="simple-controlled"
                                        value={value}

                                    />
                                </div>

                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex">
                                        <Link className="f-link"><PiShoppingCartSimple className="links-card" /></Link>
                                    </div>
                                    <div>
                                        <span className="f-links d-flex align-items-center">
                                            <span className="f-links d-flex align-items-center">
                                                {/* {pct.price > 0 ?
                                        <>
                                            <span className="me-2" style={{ marginTop: "10px" }}>{pct.price}</span> <img src="http://localhost:3000/image/toman.svg" style={{ width: "25px" }} alt="" />
                                        </>
                                        :
                                        <>
                                            <span className='text-danger text-sm'>ناموجود</span>
                                        </>
                                    } */}

                                                {pct.amount > 0 && pct.price > 0 ?
                                                    <>

                                                        <span className="me-2" style={{ marginTop: "10px" }}>{pct.price}</span> <img src="http://localhost:3000/image/toman.svg" style={{ width: "25px" }} alt="" />
                                                    </>
                                                    :
                                                    <>
                                                        <span className='text-danger text-sm'>تماس بگیرید</span>
                                                    </>
                                                }

                                            </span>
                                        </span>
                                        <div><span className="main-price">{pct.discount}</span></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Link>
                </SwiperSlide>
            ))}
            <SliderNavBtns />
        </Swiper>

    );
}
