import React from 'react';
import { useSwiper } from 'swiper/react';
import { FaAnglesUp , FaAnglesDown } from "react-icons/fa6";

function Btns() {
    const swiper = useSwiper();

    return (
        <div>
            <div className=''>
                <button className='review-prev' onClick={() => swiper.slidePrev()}><FaAnglesUp /></button>
                <button className='review-next' onClick={() => swiper.slideNext()}><FaAnglesDown /></button>
            </div>
        </div>
    );
}

export default Btns;
