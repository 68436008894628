import React from 'react'
import { useSwiper } from 'swiper/react';
import { FaAngleLeft , FaAngleRight } from "react-icons/fa6";


function SliderNavBtns() {
    const swiper = useSwiper();

    return (
        <div>

            <div className='swiper-nave-btn mt-4'>
                <button className='slider-one-prev' onClick={() => swiper.slidePrev()}><FaAngleRight /></button>
                <button className='slider-one-next' onClick={() => swiper.slideNext()}><FaAngleLeft /></button>
            </div>
        </div>
    );
}

export default SliderNavBtns;







