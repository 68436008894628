import address from "./../../../../Image/Address.png";
import { Col, Row, Modal, Button } from 'react-bootstrap'; 
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useStateContext } from "../../../context/ContextProvider";
import axiosClient from "../../../../axios-client";
import { useState, useEffect } from 'react';

export default function Address() {
  const [province, setProvince] = useState([]);
  const [showModal, setShowModal] = useState(false); 
  const [modalMessage, setModalMessage] = useState(""); 
  const [modalVariant, setModalVariant] = useState(""); 

  const { customer } = useStateContext();
  const [customerDetail, setCustomerDetail] = useState({
    address: '',
    zip_code: '',
    province: '',
  });

  useEffect(() => {
    getCustomer();
    getProvince();
  }, []);

  const getCustomer = () => {
    axiosClient.get(`/getCustomerDetail/${customer}`)
      .then(({ data }) => {
        setCustomerDetail(data.data.customer);
      })
      .catch((err) => {
        console.error("Error fetching customer details:", err);
      });
  };

  const getProvince = () => {
    axiosClient.get('/getProvince')
      .then(({ data }) => {
        setProvince(data.data);
      })
      .catch((err) => {
        console.error("Error fetching provinces:", err);
      });
  };

  const onSubmit = (ev) => {
    ev.preventDefault();
    axiosClient.post('customer/update', customerDetail)
      .then(() => {
        setModalMessage("آدرس با موفقیت ذخیره شد.");
        setModalVariant("success");
        setShowModal(true); 
      })
      .catch(() => {
        setModalMessage("مشکلی در ذخیره آدرس به وجود آمد، لطفا دوباره امتحان کنید.");
        setModalVariant("danger");
        setShowModal(true); 
      });
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <div>
            <span className='me-lg-3 '><img src={address} alt="" className="img-fluid details-prof" /></span>
            <span> آدرس</span>
          </div>
          <button className='btn btn-warning' type='submit'>ذخیره آدرس</button>
        </div>
        <Row className='gy-3 gy-lg-5 mt-1 mb-3 my-lg-4 ms-lg-3'>
          <Col xs={12} lg={6}>
            <div className='pardakht-input-lable'>
              استان  :
              <span style={{ color: "red" }} className='fs-5'>*</span>
            </div>
            <div className=''>
              <Select
                className='pardakht-input w-100'
                value={customerDetail.province} 
                onChange={ev => setCustomerDetail({ ...customerDetail, province: ev.target.value })} 
              >
                {province.map((pro, index) => (
                  <MenuItem key={index} value={pro.name}>
                    {pro.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className='pardakht-input-lable'>
              کد پستی  :
              <span style={{ color: "red" }} className='fs-5'>*</span>
            </div>
            <div className='mt-2'>
              <input
                className='pardakht-input w-100'
                type="text"
                value={customerDetail.zip_code}
                onChange={ev => setCustomerDetail({ ...customerDetail, zip_code: ev.target.value })}
              />
            </div>
          </Col>
          <Col xs={12} lg={12}>
            <div className='pardakht-input-lable'>
              آدرس کامل :
              <span style={{ color: "red" }} className='fs-5'>*</span>
            </div>
            <div className='mt-2'>
              <input
                className='pardakht-input w-100'
                type="text"
                value={customerDetail.address}
                onChange={ev => setCustomerDetail({ ...customerDetail, address: ev.target.value })}
              />
            </div>
          </Col>
        </Row>
      </form>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modalVariant === "success" ? "موفقیت" : "خطا"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`text-${modalVariant}`}>
          {modalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            بستن
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
