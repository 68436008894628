const allOrders = [
    {
        orderCount: 3598,
        orderProp : [
            {name : "اینه بغل دنا سمت " , number : 2 , price : 3_600_000},
            {name : "اینه   سمت چپ" , number : 4 , price : 3_100_000},
            {name : "آپگریدر" , number : 1 , price : 1_400_000},
        ],
        date : "22/12/1402",
        totalPrice : 8_100_000
    },
    {
        orderCount: 5874,
        orderProp : [
            {name : "اینه بغل دنا سمت راست" , number : 2 , price : 3_600_000},
            {name : "اینه بغل دنا سمت چپ" , number : 4 , price : 3_100_000},
            {name : "لنت ترمز" , number : 1 , price : 1_400_000},
        ],
        date : "14/12/1402",
        totalPrice : 8_500_000
    },
    {
        orderCount: 5874,
        orderProp : [
            {name : "اینه بغل دنا سمت چپ" , number : 4 , price : 3_100_000},
        ],
        date : "14/12/1402",
        totalPrice : 8_500_000
    }
]

export default allOrders