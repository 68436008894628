import React from 'react'
import { Col, Container, Row, Form } from 'react-bootstrap'
import imgnot from "./../../Image/404not.png"
import imgtext from "./../../Image/404text.png"
import logo from "./../../Image/logo404.png"
import arrow1 from "./../../Image/arrow1.png"
import arrow2 from "./../../Image/arrow2.png"
import arrow3 from "./../../Image/arrow3.png"
import arrow4 from "./../../Image/arrow4.png"
import arrowdes from "./../../Image/arrowdes.png"
import arrowdes2 from "./../../Image/arrowdes2.png"
import arrowdes3 from "./../../Image/arrowdes3.png"
import cat1 from "./../../Image/cat1.png"
import cat2 from "./../../Image/cat2.png"
import cat3 from "./../../Image/cat3.png"

import { Link } from 'react-router-dom'
import "./allProductNotFound.css"
export default function AllProductNotFound() {
  return (
    <Container>
    <Row className='mt-4'>
      <Col className='order-2 order-lg-1 mt-5'>
        <div><h2>صفحه ای که دنبالش میگردی پیدا نشد!!!</h2></div>
        <div className='d-flex mt-4 justify-content-center'>
          <div><img className='w-75' src={arrow1} alt="" /></div>
          <div className='ms-lg-4'><h5 style={{ fontWeight: "200" }}>میتونی دوباره جستجو کنی ...</h5></div>
        </div>
        <div className='text-center mt-3'>
          <Form style={{ position: 'relative' }}>
            <Form.Control className=' search-input-m serarch-erroepage' placeholder='جست و جو کنید ' type='text' ></Form.Control>
          </Form>
        </div>
      </Col>
      <Col className='order-1 order-lg-2' lg={6}>
        <div className='box-404-images'>
          <img className='img-logo' src={logo} alt="" />
          <img className='img-not' src={imgnot} alt="" />
          <img className='img-text' src={imgtext} alt="" />
        </div>
      </Col>
    </Row>
    <div className='box-rows mt-4'>
      <h2 className='text-arrows-s text-arrows text-center' style={{fontWeight : "200"}}>شاید اینجا پیداش کنی!!</h2>
      <img className='arrow-2 img-fluid d-lg-none' src={arrow2} alt="" />
      <img className='arrow-3 img-fluid d-lg-none' src={arrow3} alt="" />
      <img className='arrow-4 img-fluid d-lg-none' src={arrow4} alt="" />
      <img className='arrow-2-s img-fluid d-none d-lg-block' src={arrowdes} alt="" />
      <img className='arrow-3-s img-fluid d-none d-lg-block' src={arrowdes2} alt="" />
      <img className='arrow-4-s img-fluid d-none d-lg-block' src={arrowdes3} alt="" />
    </div>
    <Row className='mt-cat px-lg-5'>
      <Col xs={4} className='p-0 text-center'>
        <Link>
          <img className='img-fluid img-cats-s' src={cat1} alt="" />
        </Link>
      </Col>
      <Col xs={4} className='p-0 text-center'>
        <Link>
          <img className='img-fluid img-cats-s' src={cat2} alt="" />
        </Link>
      </Col>
      <Col xs={4} className='p-0 text-center'>
        <Link>
          <img className='img-fluid img-cats-s' src={cat3} alt="" />
        </Link>
      </Col>

    </Row>
  </Container>
  )
}
