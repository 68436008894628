import React from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import "./Footer.css"
import { FaWhatsapp } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { SiAparat, SiYoutube } from "react-icons/si";

import { AiOutlineExclamationCircle } from "react-icons/ai";
import { IoIosCard } from "react-icons/io";
import { AiOutlineCalculator } from "react-icons/ai";
import { GrArticle } from "react-icons/gr";
import { BsGrid3X3 } from "react-icons/bs";
import { SiGmail } from "react-icons/si";
import { FaTelegram } from "react-icons/fa";
import { Link } from 'react-router-dom'
import enamadImg from "../../Image/logo (1) 1.png"
import majazi from "../../Image/majazi 1.png"
import samandehi from "../../Image/samandehi 1.png"
import { MdConstruction } from 'react-icons/md';
import axiosClient from "../../axios-client";
import { useEffect, useState } from "react";
import { FaYoutube } from "react-icons/fa";







export default function Footer() {
    const [mainCategory, setMainCategory] = useState([]);

    useEffect(() => {
        getMainCategory();
    }, [])

    const getMainCategory = () => {
        axiosClient.get('/getMainCategories')
            .then(({ data }) => {
                setMainCategory(data.data)
            })
    }
    return (
        <footer>
            <Container className='footer p-4 p-lg-5'>
                <Row className='gx-lg-6'>
                    <Col xs={6} lg={5} className='phone-line'>
                        <Row className='align-items-center F-title position-relative'>
                            <Col sm={6} lg={12} ><span className='main-color fw-bold logo-footer fs-1'> آپگریدر</span></Col>
                            <Col sm={6} lg={12} className='text-end text-lg-center'><span className='fs-5 footer-p'> مرجع تخصصی تقویت و تعمیر موتور ef7</span></Col>
                        </Row>
                        <div className='py-4'>
                            <p className='fs-7 footer-p' style={{ textAlign: "justify" }}>
                                آپگریدر مجموعه‌ای تخصصی است که با تمرکز و اشراف کامل بر روی موتورهای EF7، خدمات تعمیر و تقویت این نوع موتور را به شما عزیزان ارائه می‌دهد. تیم مجرب و کارآزموده ما، با سال‌ها سابقه در زمینه تعمیر و ارتقای موتورهای EF7، به طور کامل بر نقاط قوت و ضعف این موتورها مسلط بوده و با استفاده از دانش و تجربه خود، بهترین راه‌حل‌ها را برای رفع ایرادات و ارتقای عملکرد آن ارائه می‌کنند.
                            </p>
                        </div>
                        <div className='d-none d-md-block  '>
                            <Row className='gx-0 align-items-around'>
                                <Col xs={6}><h3 className='fs-5  m-0'>با ما در تماس باشید</h3></Col>
                                <Col xs={6}>
                                    <div className='text-end'>
                                        <div className='fs-3 text-secondary position-relative F-phone pb-1'> 021<span className='main-color me-2'>28429102</span></div>
                                    </div>
                                </Col>
                                <Col className='F-title position-relative after-two'> </Col>
                                <Col xs={12}>
                                    <div className='mt-1 text-center'>
                                        
                                        <span className='mx-3'>
                                            <a href='https://instagram.com/upgrader.ir'>
                                                <FaInstagram className='text-danger fs-4' />
                                            </a>
                                        </span>
                                        <span className='mx-2'>
                                            <a href='https://t.me/Upgrader_Official'>
                                                <FaTelegram className='text-primary fs-4' />
                                            </a>
                                        </span>
                                        <span className='mx-2'>
                                            <a href='https://www.aparat.com/Upgrader/'>
                                                <SiAparat className='text-danger fs-4' />
                                            </a>
                                        </span>
                                        <span className='mx-2'>
                                            <a href='https://www.youtube.com/@Upgrader-ir'>
                                                <SiYoutube className='text-danger fs-4' />
                                            </a>
                                        </span>


                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={6} lg={5}>
                        <div>
                            <h6 className='main-color py-1 pb-lg-3'>
                                دسته بندی محصولات
                            </h6>
                            <div className='d-none d-lg-block'>
                                <ul className='row row-cols-2 row-cols-lg-3 gy-3 F-category p-0'>
                                    {mainCategory.map((mainCategory, index) => (
                                        <Link to={'/product-category/' + mainCategory.slug + '/'} key={index}><li>{mainCategory.name}</li></Link>

                                    ))}
                                </ul>
                            </div>
                            <div className='d-lg-none'>
                                <ul className='row row-cols-2 row-cols-lg-3 p-0 access-phone'>
                                    <li>محصولات</li>
                                    <li>مواد اولیه</li>
                                    <li>خدمات مجموعه</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <h5 className='main-color py-1 py-lg-3'>
                                دسترسی سریع
                            </h5>
                            <ul className='row row-cols-2 row-cols-lg-3 gy-3 p-0 quik-access' style={{ listStyle: "none" }}>
                                <Link to={"/contact-us"}><li> <FaPhone className='main-color me-1' />  تماس باما</li></Link>
                                <Link to={"/introduction/"}><li> <AiOutlineExclamationCircle className='main-color me-1' />  درباره ما </li></Link>
                                <Link><li> <IoIosCard className='main-color me-1' />  رزرو وقت </li></Link>
                                <Link to={"/category/خدمات-مجموعه-آپگریدر/"}> <li> <MdConstruction className='main-color me-1' />  خدمات مجموعه </li></Link>
                                <Link to={"/blog"}><li> <GrArticle className='main-color me-1' />  مقالات </li></Link>
                                <Link><li> <BsGrid3X3 className='main-color me-1' />   قیمت </li></Link>


                            </ul>
                        </div>
                        <div>
                            <h5 className='main-color pt-lg-3'>
                                آدرس ما
                            </h5>
                            <address className='text-lg-center t-2 fs-sm-7'>
                                مهرآباد _  بزرگراه فتح (غرب به شرق )_ نرسیده به بیمارستان شریعت رضوی _ بلوار طالقانی _ خیابان میر قادری _ پلاک 128
                            </address>

                        </div>
                        <Row className='gx-0 align-items-center d-md-none'>
                            <Col xs={6}><h3 className='fs-7 fs-lg-5 text-center m-0'>با ما در تماس باشید</h3></Col>
                            <Col xs={6}>
                                <div className='text-start'>
                                    {/* <div className='fs-6 text-secondary F-title position-relative F-phone pb-1'> 021<span className='main-color'>445566</span></div> */}
                                    <div className='mt-1'>
                                        
                                        <span className='mx-1 mx-lg-2'>
                                            <a href='https://instagram.com/upgrader.ir'>
                                                <FaInstagram className='text-danger fs-4' />
                                            </a>
                                        </span>
                                        <span className='mx-1 mx-lg-2'>
                                            <a href='https://t.me/Upgrader_Official'>
                                                <FaTelegram className='text-primary fs-4' />
                                            </a>
                                        </span>
                                        <span className='mx-1 mx-lg-2'>
                                            <a href='https://www.aparat.com/Upgrader/'>
                                                <SiAparat className='text-danger fs-4' />
                                            </a>
                                        </span>
                                        <span className='mx-1 mx-lg-2'>
                                            <a href='https://www.youtube.com/@Upgrader-ir'>
                                                <SiYoutube className='text-danger fs-4' />
                                            </a>
                                        </span>


                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={2}>
                        <Row className='row-cols-lg-1 h-100'>
                            <Col className='text-center'><Link to={"https://trustseal.enamad.ir/?id=213582&Code=oit53jJfSoGc37gz0mYz"}><img alt='test' src={enamadImg} /></Link></Col>
                            <Col className='text-center'><Link><img alt='test' src={majazi} /></Link></Col>
                            <Col className='text-center'><Link><img alt='test' src={samandehi} /></Link></Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}
