import { useEffect, useState } from "react";
import { Row, Col } from 'react-bootstrap';
import './service.css';
import { Link } from 'react-router-dom';
import axiosClient from "../../../../axios-client";

export default function Service({ setLevel }) {
  const apiKey = process.env.REACT_APP_API_KEY;

  const [activeIndex, setActiveIndex] = useState(null);
  const [Reservations, setReservations] = useState([]);

  // Add active handler to set the reservation's index and save it to localStorage
  const addActiveHandler = (index, reservationId) => {
    setActiveIndex(index);
    localStorage.setItem('selectedReservationId', reservationId); // Store the selected reservation ID in localStorage
  };

  // Fetch reservations from the API
  useEffect(() => {
    getReservations();
    const storedReservationId = localStorage.getItem('selectedReservationId');
    if (storedReservationId) {
      // Find the reservation that matches the stored ID and set it as active
      const reservationIndex = Reservations.findIndex(
        (reservation) => reservation.id === storedReservationId
      );
      if (reservationIndex !== -1) {
        setActiveIndex(reservationIndex); // Set the active index based on stored ID
      }
    }
  }, []); // Empty dependency array ensures this runs once on component mount

  const getReservations = () => {
    axiosClient.get('/getReservations')
      .then(({ data }) => {
        setReservations(data.data);
      });
  };

  return (
    <div>
      <div className='fs-4 py-2 mb-3' style={{ borderBottom: "solid 1px black" }}>انتخاب سرویس</div>
      <div className='service-box'>
        {Reservations.map((srv, index) => (
          <Row
            onClick={() => addActiveHandler(index, srv.id)} // Send both index and reservation ID to handler
            className={`align-items-center service-row my-3 ${activeIndex === index ? 'active-service-row' : ''}`} // Add 'active' class if the index is active
            key={srv.id} // Use unique reservation ID as key
          >
            <Col className='text-center'>
              <img className='img-fluid w-50' src={apiKey + srv.photo} alt="" />
            </Col>
            <Col>{srv.name}</Col>
            <Col>{srv.time}</Col>
            <Col>{srv.price}</Col>
            <Col>{srv.category.name}</Col>
            <Row>
              <Col className="mx-5 my-1">
                {srv.describtion}
              </Col>
            </Row>
          </Row>
        ))}
      </div>
      <div className='fs-4 py-2 text-end mt-3' style={{ borderTop: "solid 1px black" }}>
        <Link
          className='res-next-step'
          onClick={() => setLevel(2)}
          style={{ pointerEvents: activeIndex === null ? 'none' : 'auto', opacity: activeIndex === null ? 0.5 : 1 }}
        >
          انتخاب زمان و تاریخ
        </Link>
      </div>
    </div>
  );
}
