import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import linLink from "../../Image/linlink.png"
import homeicon from "../../Image/homeicon.png"

export default function NavLinks(props) {
  return (
    <section className='my-3'>
      <Container>
        <Link to={"/"}><span><img src={homeicon} style={{ width: "19px" }} alt="home" /></span></Link>

        {props[0] && (
          <>
            <span><img src={linLink} className='mx-2' style={{ width: "30px" }} alt="" /></span>
            <Link to={props[0].link}><span className='nav-links fs-7'>{props[0].name}</span></Link>
          </>
        )}

        {props[1] && (
          <>
            <span><img src={linLink} className='mx-2' style={{ width: "30px" }} alt="" /></span>
            <Link to={props[1].link}><span className='nav-links fs-7'>{props[1].name}</span></Link>
          </>
        )}

      </Container>
    </section>
  )
}
