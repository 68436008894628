import { Col, Container, Row } from 'react-bootstrap'
import "./Pardakht.css"

import { IoNewspaperOutline } from "react-icons/io5";
import logo from "./../../Image/logo.png"
import { Link, Outlet, Navigate } from 'react-router-dom'


import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import melli from "./../../Image/melli.png"
import mellat from "./../../Image/mellat.png"

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from 'react';
import { useStateContext } from "../context/ContextProvider";


export default function Pardakht() {
    const { customer, token, setCustomer, setToken, notification } = useStateContext();

    if (!token) {
        return <Navigate to="/login" />
    }



    return (
        <Container>
            <Row className='mt-4'>
                <Col lg={8}>
                    <div className='mb-5'>
                        <h5 className='cart-title mb-5 mt-3'>
                            <span className='fs-6'>جزئیات سفارش</span>
                            <span className='box-discount fs-6'>
                                قبلا ثبت سفارش کرده ام
                                <span style={{ color: "" }}><IoNewspaperOutline /></span>
                            </span>
                        </h5>
                    </div>
                    <div className=''>
                        <Row className='mt-5 gy-5'>
                            <Col xs={6} lg={4}>
                                <div className='pardakht-input-lable'>
                                    نام :
                                    <span style={{ color: "red" }} className='fs-5'>*</span>
                                </div>
                                <div className='mt-2'>
                                    <input className='pardakht-input' type="text" />
                                </div>
                            </Col>
                            <Col xs={6} lg={4}>
                                <div className='pardakht-input-lable'>
                                    نام خانوادگی :
                                    <span style={{ color: "red" }} className='fs-5'>*</span>
                                </div>
                                <div className='mt-2'>
                                    <input className='pardakht-input' type="text" />
                                </div>
                            </Col>
                            <Col xs={6} lg={4}>
                                <div className='pardakht-input-lable'>
                                    شماره موبایل :
                                    <span style={{ color: "red" }} className='fs-5'>*</span>
                                </div>
                                <div className='mt-2'>
                                    <input className='pardakht-input' type="tel" />
                                </div>
                            </Col>
                            <Col xs={6} lg={4}>
                                <div className='pardakht-input-lable'>
                                    کد پستی  :
                                    <span style={{ color: "red" }} className='fs-5'>*</span>
                                </div>
                                <div className='mt-2'>
                                    <input className='pardakht-input' type="text" />
                                </div>
                            </Col>
                            <Col xs={6} lg={4}>
                                <div className='pardakht-input-lable'>
                                    استان  :
                                    <span style={{ color: "red" }} className='fs-5'>*</span>
                                </div>
                                <div className='mt-2'>
                                    <Select
                                        className='pardakht-input'
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
   
                                    >

                                        <MenuItem value="">Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </div>
                            </Col>
                            <Col xs={6} lg={4}>
                                <div className='pardakht-input-lable'>
                                    ایمیل :
                                    <span style={{ color: "red" }} className='fs-5'>*</span>
                                </div>
                                <div className='mt-2'>
                                    <input className='pardakht-input' type="text" />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='pardakht-input-lable'>
                                    ادرس کامل :
                                    <span style={{ color: "red" }} className='fs-5'>*</span>
                                </div>
                                <div className='mt-2'>
                                    <input className='pardakht-input' style={{ width: "100%" }} type="text" />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='pardakht-input-lable'>
                                    توضیحات  :
                                    <span style={{ color: "red" }} className='fs-5'>*</span>
                                </div>
                                <div className='mt-2'>
                                    <input className='pardakht-input' style={{ width: "100%" }} type="text" />
                                </div>
                            </Col>
                        </Row>
                        <div className='d-flex align-items-center justify-content-center mt-5'>
                            <div><img className='me-4' style={{ width: "90px" }} src={logo} alt="" /></div>
                            <div className='fs-4'>لذت یک خرید مطمئن !</div>
                        </div>
                    </div>
                </Col>
                <Col lg={4} className='mt-5 mt-lg-0'>
                    <div className='box-information'>
                        <div className='text-center mt-4'>
                            سفارش شما
                        </div>

                        <div className='d-flex justify-content-between align-items-center my-5'>
                            <div className=''>
                                <div className='d-flex justify-content-between my-2' style={{ minWidth: "100px", fontWeight: "300" }}>
                                    <span>اکتان ووفر</span>
                                    <span>*3</span>
                                </div>
                                <div className='d-flex justify-content-between my-2' style={{ minWidth: "100px", fontWeight: "300" }}>
                                    <span>اکتان ووفر</span>
                                    <span>*3</span>
                                </div>
                                <div className='d-flex justify-content-between my-2' style={{ minWidth: "100px", fontWeight: "300" }}>
                                    <span>اکتان ووفر</span>
                                    <span>*3</span>
                                </div>
                                <div className='d-flex justify-content-between my-2' style={{ minWidth: "100px", fontWeight: "300" }}>
                                    <span>اکتان ووفر</span>
                                    <span>*3</span>
                                </div>
                            </div>
                            <span>test</span>
                        </div>
                        <div className='d-flex justify-content-between my-5'>
                            <span>  مبلغ تخفیف :</span>
                            <span>test</span>
                        </div>
                        <div className='d-flex justify-content-between my-5'>
                            <span>   مبلغ کل  :</span>
                            <span>test</span>
                        </div>
                        <div className='mb-4'>
                            <Tabs

                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                                className='img-tab'
                            >
                                <Tab value="one" label={<img className='img-fluid' src={mellat} alt="" />} />
                                <Tab value="two" label={<img className='img-fluid' src={melli} alt="" />} />
                                <Tab value="three" label={<img className='img-fluid' src={mellat} alt="" />} />
                            </Tabs>
                        </div>
                        <div className='text-center' style={{ fontWeight: "300" }}>
                            در صورت بروز مشکل درگاه دیگره را انتخاب نمایید
                        </div>
                        <div>
                            <button className='countine-btn'>
                                مبلغ قابل پرداخت <span className='mx-2'> test </span> تومان
                            </button>
                        </div>
                        <div className='text-center mb-2' style={{ fontWeight: "300" }}>
                            مبلغ پرداختی با احتساب مالیات بر ارزش افزوده می باشد .
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className='my-5'>
                <Col>
                    <ul style={{ borderRight: "solid 2px #F6A413" }}>
                        <li className='fs-4 my-3' style={{ listStyle: "none", color: "red" }}>توجه</li>
                        <li>
                            <p>
                                هزینه ارسال بصورت پس کرایه میباشد
                            </p>
                        </li>
                        <li>
                            <p>
                                با توجه به حجم بالای سفارشات ممکن است سفارشات تا 48 ساعت پس از ثبت سفارش ارسال شوند.از صبر و شکیبایی شماسپاس گزاریم.
                            </p>
                        </li>
                        <li>
                            <p>
                                با توجه به اتصال درگاه های پرداختی مجموعه به شبکه مالیاتی کشور در صورت لغو سفارش پس از پرداخت و ثبت آن مقدار 9% از مبلغ کل سفارش کسر شده و مابقی مبلغ به حساب شما واریز می گردد . از این رو از شما عزیزان خواهشمندیم در خرید خود دقت فرمایید .
                            </p>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container >
    )
}
