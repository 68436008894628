import React from 'react'
import order from "./../../../../Image/Details.png"
import allOrders from '../../../../Data/orders'
import { Col, Row } from 'react-bootstrap'
import "./Orders.css"
import { Navigate } from 'react-router-dom'
import { useStateContext } from "../../../context/ContextProvider";

export default function Orders() {
  // const { customer, token } = useStateContext();
  // if (token || customer.name === null) {
  //   return <Navigate to="/myaccount/details" />
  // }
  return (
    <div>
      <div className='mt-4'>
        <span className='me-lg-3 '><img src={order} alt="" className="img-fluid details-prof" /></span>
        <span>سفارشات</span>
      </div>
      <div className='mt-5 mb-3'>
        <table className='w-100' style={{ lineHeight: "3" }}>
          <tr className='order-header mb-3'>
            <th className='order-number text-center'>شماره</th>
            <th className='order-item text-center d-none d-lg-block'>آیتم ها</th>
            <th className='order-date text-center'>تاریخ ثبت</th>
            <th className='order-price text-center'>مبلغ</th>
          </tr>

          {allOrders.map((ord, index) => (
            <>
              <tr key={index} className='order-item-box my-3 py-3'>
                <td className='text-center'>{ord.orderCount}</td>
                <td className='text-center d-none d-lg-block'>
                  {ord.orderProp.map((ordProp, propIndex) => (
                    <Row key={propIndex}>
                      <Col>{ordProp.name}</Col>
                      <Col>{ordProp.number}</Col>
                      <Col>{ordProp.price}</Col>
                    </Row>
                  ))}
                </td>
                <td className='text-center'>{ord.date}</td>
                <td className='text-center'>{ord.totalPrice}</td>
              </tr>
              <tr className='d-lg-none order-item-box-2'>
                <td colSpan="4" className='text-center'>
                  {ord.orderProp.map((ordProp, propIndex) => (
                    <Row key={propIndex}>
                      <Col xs={6} className='order-item-phone'>{ordProp.name}</Col>
                      <Col xs={3} className='order-item-phone'>{ordProp.number}</Col>
                      <Col xs={3} className='order-item-phone'>{ordProp.price}</Col>
                    </Row>
                  ))}
                </td>
              </tr>
            </>
          ))}

        </table>
      </div>
    </div>
  )
}
