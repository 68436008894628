import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import 'swiper/css';
import 'swiper/css/pagination';
import "./Advetise.css"
import { Pagination } from 'swiper/modules';

export default function Advertise() {
  const [advertisments, setAdvertisments] = useState([]);
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    axiosClient.get(`/getAdvertisment`)
      .then(({ data }) => {
        setAdvertisments(data.data)
      })
  }, [])
  return (
    <>
      <h5 className='interdues text-center mb-5'>معرفی ها</h5>
      <Swiper pagination={true} modules={[Pagination]} className="addvertise-swiper advertise-slide">
        {advertisments.map((advertisment, index) => (
          <SwiperSlide key={index}>
            <div className='advertise-slid'>
              <h6>
                {advertisment.name}
              </h6>
              <img src={apiKey + advertisment.photo}  className='img fluid' alt={advertisment.name} />
              <h6 className='my-3'>
                {advertisment.title}
              </h6>

              <span>
                <a href={advertisment.link} style={{ color: "red", fontWeight: "200" }}>
                 {advertisment.link}
                </a>
              </span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}
