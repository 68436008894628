import Index from './Component/Index/Index';
import SingleProduct from './Component/SingleProduct/SingleProduct';
import AllProducts from './Component/AllProducts/AllProducts';
import Categories from './Component/AllProducts/Categories';
import MainCategories from './Component/MainCategories/MainCategories';
import ProductCategories from './Component/ProductCategories/ProductCategories';
import SingleBlog from './Component/SingleBlog/SingleBlog';
import Cart from './Component/Cart/Cart';
import Blogs from './Component/Blogs/Blogs';
import Khadamat from './Component/Blogs/Khadamat';
import Pardakht from './Component/Pardakht/Pardakht';
import MyAccount from './Component/MyAccount/MyAccount';
import Main from './Component/MyAccount/Components/Main/Main';
import Details from './Component/MyAccount/Components/Details/Details';
import Orders from './Component/MyAccount/Components/Orders/Orders';
import WishList from './Component/MyAccount/Components/Wishlist/WishList';
import Address from './Component/MyAccount/Components/Address/Address';
import Introduction from './Component/Introduction/Introduction';
import Tracking from './Component/Tracking/Tracking';
import Contact from './Component/Contact/Contact';
import RulesShop from './Component/RulesShop/RulesShop';
import Login from './Component/Login/Login';
import NotFound from './Component/NotFound/NotFound';  // Import the NotFound component
import AllProductNotFound from './Component/allProductNotFound/allProductNotFound';
import Reservation from './Component/Reservation/Reservation';


import DefaultLayout from './Component/DefaultLayout';
import { createBrowserRouter, Navigate } from "react-router-dom";

const AllRoutes = createBrowserRouter([
    {
        path: '/',
        element: <DefaultLayout />,
        children: [
            {
                path: '/',
                element: <Index />
            },
            {
                path: "product/:productSlug/",
                element: <SingleProduct />
            },
            { path: ":slug/", element: <SingleBlog /> },
            { path: "cart", element: <Cart /> },
            { path: "blog/", element: <Blogs /> },
            { path: "category/خدمات-مجموعه-آپگریدر/", element: <Khadamat /> },
            { path: "pardakht", element: <Pardakht /> },
            { path: "contact-us/", element: <Contact /> },
            { path: "rules", element: <RulesShop /> },
            { path: "پیگیری-سفارشات/", element: <Tracking /> },
            { path: "introduction/", element: <Introduction /> },

            {
                path: "product-category", element: <Categories />,
                children: [
                    {
                        path: ":slug",
                        element: <MainCategories />,
                    },
                    {
                        path: "all-products",
                        element: <AllProducts />,
                    },
                    {
                        path: ":slug/:categorySlug",
                        element: <ProductCategories />,
                    },
                    { path: "*", element: <AllProductNotFound /> },

                ],
            },
            {
                path: "myaccount", element: <MyAccount />,
                children: [
                    {
                        path: "",
                        element: <Main />,
                    },
                    {
                        path: "details",
                        element: <Details />,
                    },
                    {
                        path: "orders",
                        element: <Orders />,
                    },
                    {
                        path: "wishlist",
                        element: <WishList />,
                    },
                    {
                        path: "address",
                        element: <Address />,
                    },
                ],
            },
            { 
                path: "/reservation", element: <Reservation />,
            },            
        ]
        
    },
    { path: "login", element: <Login /> },

    { path: "*", element: <NotFound /> },
]);

export default AllRoutes;
