import React from 'react'
import "./Introduction.css"
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import NavLinks from '../NavLinks/NavLinks'
import logo from "./../../Image/logo-about.png"
import upgraders from "./../../Image/upgraderes.png"
import one from "./../../Image/one.png"
import oneSection from "./../../Image/جلو امپر.jpg"
import two from "./../../Image/2.png"
import power from "./../../Image/2 (2).png"
import summer from "./../../Image/3.png"
import control from "./../../Image/4.png"
import reflector from "./../../Image/5.png"
import lamp from "./../../Image/6.png"
import lamps from "./../../Image/7.png"
import rahnama from "./../../Image/8.png"
import markaz from "./../../Image/9.png"
import speed from "./../../Image/10.png"
import moreOption from "./../../Image/11.png"
import a from "./../../Image/a (2).jpg"
import b from "./../../Image/b (2).jpg"
import c from "./../../Image/c.jpg"
import d from "./../../Image/d.jpg"
import e from "./../../Image/e.jpg"
import f from "./../../Image/f.jpg"
import g from "./../../Image/g.jpg"
import h from "./../../Image/h (2).jpg"
import i from "./../../Image/i.jpg"


import { useEffect } from "react";

export default function About() {
    useEffect(() => {
        document.title = 'معرفی آپگریدر';
    }, [])

    const link = [{
        name: 'معرفی آپگریدر',
        link: '/introduction/',
    }];

    return (
        <div>
            <Container>
                <NavLinks {...link} />
            </Container>
            <Container className='about-baner mt-lg-5' fluid>
                <Container>
                    <Row className='' style={{ alignItems: "baseline" }}>
                        <Col className='text-center'>
                            <h2 className='text-center title-about-baner'>
                                <span style={{ color: "#F6A413" }}>آپگریدر</span> هوشمند ساز خودرو
                            </h2>
                            <img className='img-fluid w-75 text-center' src={logo} alt="" />
                        </Col>
                        <Col>
                            <h2 className='text-center lft-title mt-3 d-xl-none'>
                                مخصوص خودرو های خانواده سورن ودنا
                            </h2>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className='bg-light interdus-upgrader-box py-2 py-lg-5 rounded-2'>
                <div className='row'>
                    <div style={{ textAlign: "justify" }} className="col col-9">آپگریدر دستگاهی در ابعاد کوچک است که بدون نیاز به تغییر سیم کشی و تنها با اتصال به پورت عیب یابی خودرو فعال شده و به سلیقه شما شروع به کار می کند!
                        <br></br> به همین راحتی !
                        <span className='abslut-title'>
                            معرفی آپگریدر
                        </span>
                    </div>
                    <div className="col col-3">
                        <img className='img-fluid upgraders' src={upgraders} alt="" />
                    </div>
                </div>
            </Container>
            <Container>
                <h2 className='text-center my-4'>
                    آپشن ها
                </h2>
                <Tabs

                    defaultActiveKey=""
                    id="uncontrolled-tab-example"
                    className="text-center border-0 justify-content-around"

                >
                    <Tab className='tab-content-custom' eventKey="1" title={<img src={one} className='img-tab-head' />}>
                        <Row className=''>
                            <Col lg={8}>
                                <div className='mt-3'><h3>🔥 چراغ روز (DayLight) </h3></div>
                                <div className='mt-lg-5 mt-2'> یک آپشن کاربر پسند برای زیبایی ظاهر خودرو در روز که جلوه خاصی را به چراغ های جلو می بخشد.
                                    امروزه تمامی خودرو های لوکس دارای این آپشن می باشند که جای خالی آن در خودرو دنا(با وجود LED های زیبا) احساس می شد و با دستگاه آپگریدر این آپشن زیبا به خودرو اضافه می شود.با استارت خودرو ، دیلایت ها روشن شده و در تمام مدت رانندگی خودنمایی می کنند.
                                    دیلایت فقط LED های جلویی خودرو است و چراغ های عقب و سایز چراغ ها در طول روز خاموش هستند.
                                    این آپشن مختص خودرو دنا و سورن می باشد و برای خودرو دناپلاس این آپشن عملکرد دیگری دارد.</div>
                            </Col>
                            <Col lg={4}>
                                <img src={b} className='img-fluid mt-3 mt-lg-0' alt="" />
                            </Col>
                        </Row>
                    </Tab>
                    <Tab className='tab-content-custom' eventKey="2" title={<img src={two} className='img-tab-head' />}>
                        <Row className=''>
                            <Col lg={8}>
                                <div className='mt-3'><h3>🔥 تست جلوآمپر (تست گیج ها)</h3></div>
                                <div className='mt-lg-5 mt-2'>در اکثر خودرو های استاندارد اروپایی و آمریکایی هنگام باز کردن سوییچ یک بوق ممتد به صدا در آمده و عقربه ها بالا و پایین می شوند و بدین معناست که باید چندین ثانیه به خودرو اجازه دهید تا خودرو آماده استارت و رانندگی شود.</div>
                            </Col>
                            <Col lg={4}>
                                <img src={oneSection} className='img-fluid mt-3 mt-lg-0' alt="" />
                            </Col>
                        </Row>
                    </Tab>
                    <Tab className='tab-content-custom' eventKey="3" title={<img src={power} className='img-tab-head' />}>
                        <Row className=''>
                            <Col lg={8}>
                                <div className='mt-3'><h3>🔥 تست جلوآمپر (تست گیج ها)</h3></div>
                                <div className='mt-lg-5 mt-2'>در اکثر خودرو های استاندارد اروپایی و آمریکایی هنگام باز کردن سوییچ یک بوق ممتد به صدا در آمده و عقربه ها بالا و پایین می شوند و بدین معناست که باید چندین ثانیه به خودرو اجازه دهید تا خودرو آماده استارت و رانندگی شود.</div>
                            </Col>
                            <Col lg={4}>
                                <img src={oneSection} className='img-fluid mt-3 mt-lg-0' alt="" />
                            </Col>
                        </Row>
                    </Tab>
                    <Tab className='tab-content-custom' eventKey="4" title={<img src={summer} className='img-tab-head' />}>
                        <Row className=''>
                            <Col lg={8}>
                                <div className='mt-3'><h3>🔥 حالت تابستان برای روز های جهنم تابستانی !
                                </h3></div>
                                <div className='mt-lg-5 mt-2'>خب دیگر بررسی خودرو های استاندارد کافیست و وقت آن است که آپشن های مخصوص دستگاه آپگریدر را معرفی کنیم که تا زمان تولید آپگریدر بر روی هیچ خودرویی به این هوشمندی قرار نگرفته و به صورت اختصاصی توسط آپگریدر برای دنا طراحی شده است.اما آپشن اختصاصی ما پایین آمدن شیشه ها در روز های گرم تابستانی است که باعث خالی شدن هوای گرم داخل خودرو به بیرون می شود.
                                    همچین در صورتی که شخصی مایل به نشستن در عقب خودرو باشد، با باز شدن درب عقب ، شیشه های عقب نیز خودکار به اندازه مناسب پایین خواهند آمد ! این آپشن با استفاده از دمای بیرون کار می کند و به راحتی با ریموت ، در زمان لازم ، از راه دور فعال می شود.</div>
                            </Col>
                            <Col lg={4}>
                                <img src={d} className='img-fluid mt-3 mt-lg-0' alt="" />
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
                <div className='my-lg-5'>
                    <Tabs

                        defaultActiveKey=""
                        id="uncontrolled-tab-example"
                        className="text-center border-0 justify-content-around"

                    >
                        <Tab className='tab-content-custom' eventKey="1" title={<img src={control} className='img-tab-head' />}>
                            <Row className=''>
                                <Col lg={8}>
                                    <div className='mt-3'><h3>🔥 کنترل هوشمند آینه ها </h3></div>
                                    <div className='mt-lg-5 mt-2'>خب شاید بگویید که آینه های جانبی دنا با دوبار قفل از روی ریموت قابل جمع شدن هستند و نیازی به کنترل آن ها نیست اما ما با توجه به عملکرد آینه های جانبی برقی در سایر خودرو ها تصور دیگری داریم.
                                        اکثر دنا سواران مشکل عمل نکردن ریموت و جمع نشدن آینه ها در برخی مواقع را دارند.
                                        همچنین آینه ها به صورت پیشفرض با باز کردن خودرو باز می شوند که معقول نیست (هر باز کردنی به معنای رانندگی نیست).با اتصال دستگاه آپگریدر ، عملکرد آینه ها کاملا متفاوت می شود.
                                        با یکبار قفل از روی ریموت ، آینه ها جمع شده ،شیشه ها بالا رفته و خودرو در حالت کاملا امن قرار میگیرد.
                                        با باز کردن خودرو ، آینه ها باز نشده و فقط موقع رانندگی با باز شدن سوییچ ، آینه ها نیز باز می شوند.
                                        آپشن فوق قابلیت فعال و غیر فعال سریع و آسان را نیز دارد.</div>
                                </Col>
                                <Col lg={4}>
                                    <img src={c} className='img-fluid mt-3 mt-lg-0' alt="" />
                                </Col>
                            </Row>
                        </Tab>
                        <Tab className='tab-content-custom' eventKey="2" title={<img src={reflector} className='img-tab-head' />}>
                            <Row className=''>
                                <Col lg={8}>
                                    <div className='mt-3'><h3>🔥 روشن کردن همزمان نوربالا و نورپایین (دابل رفلکتور) </h3></div>
                                    <div className='mt-lg-5 mt-2'>حتما برای شما نیز پیش آمده در شرایطی مانند جاده های آفرود یا خیابان های تاریک و ... قرار گرفته باشید و بخواهید همزمان از نوربالا و نورپایین استفاده کنید.افرادی هستند که با دریافت هزینه و تغییر در سیمکشی و رله ها این حالت را فعال می کنند اما با دستگاه آپگریدر بدون تغییر سیمکشی ، به سلیقه شما در چندین ثانیه فعال می شود ! </div>
                                </Col>
                                <Col lg={4}>
                                    <img src={e} className='img-fluid mt-3 mt-lg-0' alt="" />
                                </Col>
                            </Row>
                        </Tab>
                        <Tab className='tab-content-custom' eventKey="3" title={<img src={lamp} className='img-tab-head' />}>
                            <Row className=''>
                                <Col lg={8}>
                                    <div className='mt-3'><h3>🔥 چراغ پارک ! </h3></div>
                                    <div className='mt-lg-5 mt-2'>بله این آپشن نیز با دستگاه آپگریدر قابل استفاده بوده و به زیبایی هر چه تمام تر عمل می کند.
                                        مواقعی پیش می آید که خودرو را در جای نامناسب یا در کوچه های تاریک پارک می کنیم یا مواقعی پیش می آید که قصد بازگشت به خودرو در زمان کم را داریم.
                                        در این مواقع آپشنی به نام چراغ پارک وجود دارد که بسیار راحت فعال شده و باعث بهتر دیده شدن خودرو شما در کوچه های تاریک و شرایط نامناسب می شود تا از هر گونه آسیب به خودرو شما جلوگیری شود.در درستگاه آپگریدر 2 نوع چراغ پارک برای خودرو دنا تعریف شده است.
                                        نوع اول ، چراغ پارک نورکوچیک می باشد یعنی با فعالسازی آن نورکوچیک های سمت خیابان فعال شده و خودرو شما را در معرض دید قرار می دهد.
                                        نوع دوم ، چراغ پارک آینه می باشد یعنی با فعالسازی آن چراغ LED موجود بر روی آینه سمت خیابان روشن شده و باعث دیده شدن خودرو شما در شرایط تاریک می شود.
                                        این آپشن به صورت بهینه برای خودرو دنا طراحی شده و در تست های صورت گرفته کوچک ترین مشکلی برای خودرو به وجود نیامده است.
                                        در صورتی که به خودرو بازنگردید یا بازگشت شما بیش از چندین ساعت طول بکشد یا باتری شما در وضعیت مناسبی نباشد این آپشن به صورت خودکار غیر فعال خواهد شد تا مشکلی برای خودرو به وجود نیاید.

                                        * ممکن است نوع اول با توجه به سال تولید خودرو شما عمل نکند و جفت نورکوچیک ها روشن شوند.
                                        * جهت خیابان توسط کاربر قابل تنظیم است.</div>
                                </Col>
                                <Col lg={4}>
                                    <img src={f} className='img-fluid mt-3 mt-lg-0' alt="" />
                                </Col>
                            </Row>
                        </Tab>
                        <Tab className='tab-content-custom' eventKey="4" title={<img src={lamps} className='img-tab-head' />}>
                            <Row className=''>
                                <Col lg={8}>
                                    <div className='mt-3'><h3>🔥 اما ترکیب جدید چراغ ها ، آپشن ویژه ای که برای حودرو دناپلاس درنظر گرفته ایم.</h3></div>
                                    <div className='mt-lg-5 mt-2'>با فعالسازی این آپشن ، در هنگام شب ، با روشن کردن نورکوچیک ها ، چراغ ها ترکیب زیر را به خود می گیرند.
                                        این آپشن به صورت سلیقه ای قابلیت فعالسازی را دارد..</div>
                                </Col>
                                <Col lg={4}>
                                    <img src={a} className='img-fluid mt-3 mt-lg-0' alt="" />
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </div>
                <Tabs

                    defaultActiveKey=""
                    id="uncontrolled-tab-example"
                    className="text-center border-0 justify-content-around"

                >
                    <Tab className='tab-content-custom' eventKey="1" title={<img src={rahnama} className='img-tab-head' />}>
                        <Row className=''>
                            <Col lg={8}>
                                <div className='mt-3'><h3>🔥 چهار فلاشر با یکبار زدن راهنما</h3></div>
                                <div className='mt-lg-5 mt-2'>اگر صاحب یک خودرو دنا باشید میدانید که در این خودرو با یکبار جابجا کردن دسته راهنما ، تعداد 3 راهنما زده می شود.</div>
                            </Col>
                            <Col lg={4}>
                                <img src={g} className='img-fluid mt-3 mt-lg-0' alt="" />
                            </Col>
                        </Row>
                    </Tab>
                    <Tab className='tab-content-custom' eventKey="2" title={<img src={markaz} className='img-tab-head' />}>
                        <Row className=''>
                            <Col lg={8}>
                                <div className='mt-3'><h3>🔥 تغییر سیستم قفل مرکزی خودرو</h3></div>
                                <div className='mt-lg-5 mt-2'>مجددا اگر صاحب یک خودرو دنا باشید میدانید که در این خودرو با یکبار زدن دکمه باز از روی ریموت ، فقط درب سمت راننده باز می شود و برای باز کردن سایر درب ها ، باید مجددا دکمه باز ریموت را بزنید.برای افرادی که دائما با خانواده سوار خودرو می شوند یک مشکل محسوب می شود و چندین مورد شکستن دستگیره درب نیز گزارش شده است.
                                    به سلیقه خود میتوانید با دستگاه آپگریدر باز شدن دو مرحله ای را غیر فعال کنید.</div>
                            </Col>
                            <Col lg={4}>
                                <img src={h} className='img-fluid mt-3 mt-lg-0' alt="" />
                            </Col>
                        </Row>
                    </Tab>
                    <Tab className='tab-content-custom' eventKey="3" title={<img src={speed} className='img-tab-head' />}>
                        <Row className=''>
                            <Col lg={8}>
                                <div className='mt-3'><h3>🔥 غیرفعالسازی بوق سرعت 120km/h </h3></div>
                                <div className='mt-lg-5 mt-2'>در صورتی که خواهان غیرفعالسازی این بوق باشید ، مشکلی نیست !
                                    آپگریدر این کار را برای شما به آسانی انجام می دهد.
                                    البته با موتور قدرتمند EF7 فکر نمیکنیم کسی خواهان غیرفعالسازی آن باشد اما همانطور که گفتیم ، همه چیز به سلیقه شما بستگی دارد.در خودرو دنا با رسیدن به سرعت 120 کیلومتر بر ساعت ، هشدار 3 بوق ممتد از پشت آمپر شنیده می شود.</div>
                            </Col>
                            <Col lg={4}>
                                <img src={i} className='img-fluid mt-3 mt-lg-0' alt="" />
                            </Col>
                        </Row>
                    </Tab>
                    <Tab className='tab-content-custom' eventKey="4" title={<img src={moreOption} className='img-tab-head' />}>
                        <Row className=''>
                            <Col lg={8}>
                                <div className='mt-3'><h3>🔥 سایر آپشن ها </h3></div>
                                <div className='mt-lg-5 mt-2'>پاکسازی خطای نود های شبکه مالتی پلکس<br></br>نمایش سنسور دنده عقب در صفحه نمایش جلوآمپر<br></br>نمایش دمای بدنه در صفحه نمایش جلوآمپر</div>
                            </Col>
                            <Col lg={4}>
                                <img src={oneSection} className='img-fluid mt-3 mt-lg-0' alt="" />
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>


            </Container>
        </div>
    )
}
