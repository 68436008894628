import profile from "./../../../../Image/person.png";
import { Col, Row, Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import "./Details.css";
import { useStateContext } from "../../../context/ContextProvider";
import axiosClient from "../../../../axios-client";
import { useState, useEffect } from 'react';

export default function Details() {
  const { customer } = useStateContext();
  const [customerDetail, setCustomerDetail] = useState({
    phone_number: '',
    name: '',
    last_name: '',
    email: '',
  });

  // State for modal control
  const [showModal, setShowModal] = useState(false); // Controls modal visibility
  const [modalMessage, setModalMessage] = useState(""); // Stores the message to display
  const [modalVariant, setModalVariant] = useState(""); // 'success' or 'danger' based on the action

  useEffect(() => {
    getCustomer();
  }, []);

  const getCustomer = () => {
    axiosClient.get(`/getCustomerDetail/${customer}`)
      .then(({ data }) => {
        setCustomerDetail({
          phone_number: data.data.customer.phone_number,
          name: data.data.customer.name,
          last_name: data.data.customer.last_name,
          email: data.data.customer.email,
        });
      })
      .catch(() => {
        // Handle errors if necessary
      });
  };

  const onSubmit = (ev) => {
    ev.preventDefault();
    axiosClient.post('customer/update', customerDetail)
      .then(() => {
        setModalMessage("اطلاعات حساب با موفقیت ذخیره شد.");
        setModalVariant("success");
        setShowModal(true); // Show modal on success
      })
      .catch(() => {
        setModalMessage("مشکلی در ذخیره اطلاعات به وجود آمد، لطفا دوباره امتحان کنید.");
        setModalVariant("danger");
        setShowModal(true); // Show modal on error
      });
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <div>
            <span className='me-lg-3 '><img src={profile} alt="" className="img-fluid details-prof" /></span>
            <span>جزئیات حساب</span>
          </div>
          <button className='btn btn-warning'>ذخیره اطلاعات</button>
        </div>
        <Row className='gy-3 gy-lg-5 mt-1 mb-3 my-lg-4 ms-lg-3'>
          <Col xs={12} lg={6}>
            <div className='pardakht-input-lable'>
              نام :
              <span style={{ color: "red" }} className='fs-5'>*</span>
            </div>
            <div className='mt-2'>
              <input className='pardakht-input w-100' type="text"
                value={customerDetail.name}
                onChange={ev => setCustomerDetail({ ...customerDetail, name: ev.target.value })} />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className='pardakht-input-lable'>
              نام خانوادگی :
              <span style={{ color: "red" }} className='fs-5'>*</span>
            </div>
            <div className='mt-2'>
              <input className='pardakht-input w-100' type="text"
                value={customerDetail.last_name}
                onChange={ev => setCustomerDetail({ ...customerDetail, last_name: ev.target.value })} />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className='pardakht-input-lable'>
              شماره موبایل :
              <span style={{ color: "red" }} className='fs-5'>*</span>
            </div>
            <div className='mt-2'>
              <input className='pardakht-input w-100' type="text"
                value={customerDetail.phone_number}
                onChange={ev => setCustomerDetail({ ...customerDetail, phone_number: ev.target.value })} />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className='pardakht-input-lable'>
              ایمیل :
              <span style={{ color: "red" }} className='fs-5'>*</span>
            </div>
            <div className='mt-2'>
              <input className='pardakht-input w-100' type="email"
                value={customerDetail.email}
                onChange={ev => setCustomerDetail({ ...customerDetail, email: ev.target.value })} />
            </div>
          </Col>
        </Row>
      </form>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modalVariant === "success" ? "موفقیت" : "خطا"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`text-${modalVariant}`}>
          {modalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            بستن
          </Button>
        </Modal.Footer>
      </Modal>      
    </div>
  )
} 