const Products = [
    {
        name: "لوازم یدکی",
        price: 500000,
        img: "http://localhost:3000/image/product1.jpg",
        attributes: {
            color: "red",
            size: "medium",
            weight: "1kg"
        },
        shortDec : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur sit doloribus aliquid dolor illum possimus, reprehenderit temporibus eligendi sed accusantium, itaque quis porro quisquam! Vel officia ipsam tenetur soluta laborum",
        album : [
            "../image/1.jpg",
            "../image/2.jpg",
            "../image/3.jpg"
        ]
    },
    {
        name: "فیلتر روغن",
        price: 50000,
        img: "http://localhost:3000/image/product1.jpg",
        attributes: {
            compatibility: "Toyota Corolla",
            brand: "Mann",
            material: "Synthetic",
            warranty: "6 months"
        },
        album : [
            "../image/1.jpg",
            "../image/2.jpg",
            "../image/3.jpg"
        ]
        
    },
    {
        name: "شمع موتور",
        price: 70000,
        img: "http://localhost:3000/image/product1.jpg",
        attributes: {
            compatibility: "Hyundai Elantra",
            brand: "NGK",
            type: "Iridium",
            warranty: "1 year"
        },
        album : [
            "../image/1.jpg",
            "../image/2.jpg",
            "../image/3.jpg"
        ]
    },
]

export default Products;