import React from 'react'

export default function CardProductWithAccount(props) {
   
    return (
            <div className='slide-wish mx-lg-3'>
                <div className='product-card product-card2'>
                    <div className='text-center'>
                        <span>حذف ×</span>
                    </div>
                    <div><img src={props.img} className='img-fluid card-img' alt={props.name} /></div>
                    <div className='mb-3 text-center mt-3'>{props.name}</div>

                    <div className="d-flex justify-content-center align-items-center">
                        <div>
                            <span className="f-links d-flex align-items-center justify-content-center">
                                <span className="me-2" style={{ marginTop: "10px" }}>{props.price}</span> <img src="http://localhost:3000/image/toman.svg" style={{ width: "25px" }} alt="" />
                            </span>
                            <div><span className="main-price">{props.mainPrice}</span></div>
                        </div>

                    </div>
                </div>
            </div>
    )
}
