import React from 'react'
import { Link } from 'react-router-dom'
import "./SingleBlog.css"
import logo from "../../Image/logo.png"
export default function SingleBlogCard(props) {
    const apiKey = process.env.REACT_APP_API_KEY;

    return (
        <>
            <Link to={`/${props.slug}`}>
                <div className='blg-card afterBlog blg-card2 my-5'>
                    <div className=''>
                        <div><img src={apiKey+props.photo} className='img-fluid' alt={props.name} /></div>
                        <div className='mb-3 text-center mt-3'>{props.name}</div>
                        <div>
                            <p className='limit-text'>{props.summary}</p>
                        </div>
                        <div className='text-end'>
                            <span>{`ادامه >>>`}</span>
                        </div>
                        <span className='logo-blog'>
                            <img src={logo} className='img-fluid' alt="logo" />
                        </span>
                    </div>
                </div>
            </Link>
        </>
    )
}
