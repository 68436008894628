import { Alert, Snackbar } from "@mui/material";
import {createContext, useContext, useState} from "react";

const StateContext = createContext({
  currentUser: null,
  token: null,
  notification: null,
  setCustomer: () => {},
  setToken: () => {},
  setNotification: () => {}
})

export const ContextProvider = ({children}) => {
  const [customer, _setCustomer] = useState(localStorage.getItem('phone_number'));
  const [token, _setToken] = useState(localStorage.getItem('CustomerToken'));
  const [notification, _setNotification] = useState('');

  const setToken = (token) => {
    _setToken(token)
    if (token) {
      localStorage.setItem('CustomerToken', token);
    } else {
      localStorage.removeItem('CustomerToken');
    }
  }
  const setCustomer = (customer) => {
    _setCustomer(customer)
    if (customer) {
      localStorage.setItem('phone_number', customer.phone_number);
    } else {
      localStorage.removeItem('CustomerToken');      
      localStorage.removeItem('phone_number');
    }
  }  

  const setNotification = message => {
    _setNotification(message);

    setTimeout(() => {
      _setNotification('')
    }, 5000)
  }

  return (
    <StateContext.Provider value={{
      customer,
      setCustomer,
      token,
      setToken,
      notification,
      setNotification
    }}>
      {children}
    </StateContext.Provider>
  );
}

export const useStateContext = () => useContext(StateContext);
