import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Confirm({setLevel}) {
  return (
    <div className=''>
      <div className='fs-4 py-2 mb-3' style={{ borderBottom: "solid 1px black" }}> تایید و پرداخت </div>
      <div className='service-box'>
        <Row className='my-5'>
          <Col className='text-lg-center fs-6' style={{color : "#0eab00"}}>سرویس :</Col>
          <Col className='text-lg-center fs-6'>تعویض تسمه تایم</Col>
          <Col className='text-lg-center fs-6'>2 ساعت و 30 دقیقه</Col>
          <Col className='text-lg-center fs-6'>250000</Col>
        </Row>
        <Row className='my-5'>
          <Col className='text-lg-center fs-6' style={{color : "#0eab00"}}>تاریخ و زمان :</Col>
          <Col className='text-lg-center fs-6'>چهارشنبه</Col>
          <Col className='text-lg-center fs-6'>1403/06/21</Col>
          <Col className='text-lg-center fs-6'>13:00</Col>
        </Row>
        <Row className='my-5'>
          <Col className='ms-lg-5 fs-6' style={{color : "#0eab00"}}>نام و نام خانوادگی :</Col>
          <Col className='ms-lg-5 fs-6'>علی احسانی</Col>
          
        </Row>
        <Row className='my-5'>
          <Col className='ms-lg-5 fs-6' style={{color : "#0eab00"}}>شماره موبایل :  :</Col>
          <Col className='ms-lg-5 fs-6'>09025955182</Col>
          
        </Row>
        

      </div>
      <div className='fs-4 py-2 d-flex justify-content-between mt-3' style={{ borderTop: "solid 1px black" }}>
        <Link onClick={() => setLevel(3)} className='res-prev-step'>  ویرایش اطلاعات </Link>
        <Link  className='res-next-step'> پرداخت</Link>
      </div>
    </div>
  )
}
