import wishlist from "./../../../../Image/Wishlist.png"
import { Col, Row } from 'react-bootstrap'
import products from "./../../../../Data/productData"
import CardProductWithAccount from '../Card/CardProductWithAccount'
import "./WishList.css"

import { Navigate } from 'react-router-dom'
import { useStateContext } from "../../../context/ContextProvider";
import { useState } from "react"

export default function WishList() {
  let [wishProducts] = useState(products)
  const { customer, token } = useStateContext();
  // if (token || customer.name === null) {
  //   return <Navigate to="/myaccount/details" />
  // }


  return (
    <div>
      <div className='d-flex justify-content-between align-items-center mt-4'>
        <div>
          <span className='me-lg-3 '><img src={wishlist} alt="" className="img-fluid details-prof" /></span>
          <span> علاقه مندی ها</span>
        </div>
        <span className='title-details'>خالی کردن لیست </span>
      </div>
      <Row className='gy-lg-4 mt-1 my-lg-4'>
        {wishProducts.map((ptc) => (
          <Col className='p-1' xs={6} lg={4}>
            <CardProductWithAccount {...ptc}  />
          </Col>
        ))}
      </Row>
    </div>
  )
}
