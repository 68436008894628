import React from 'react'
import { Link } from 'react-router-dom'
export default function Information({setLevel}) {
  return (
    <div className=''>
      <div className='fs-4 py-2 mb-3' style={{ borderBottom: "solid 1px black" }}> اطلاعات</div>
      <div className='service-box pt-5'>
        <div className='pardakht-input-lable'>
          نام :
        </div>
        <div className='mt-2'>
          <input className='pardakht-input w-50' type="text" />
        </div>
        <div className='pardakht-input-lable mt-5'>
        نام خانوادگی  :
        </div>
        <div className='mt-2'>
          <input className='pardakht-input w-50' type="text" />
        </div>
        <div className='pardakht-input-lable mt-5'>
          شماره موبایل :
        </div>
        <div className='mt-2'>
          <input className='pardakht-input w-50' type="text" />
        </div>
      </div>
      <div className='fs-4 py-2 d-flex justify-content-between mt-3' style={{ borderTop: "solid 1px black" }}>
        <Link onClick={() => setLevel(2)} className='res-prev-step'> انتخاب تاریخ و زمان</Link>
        <Link onClick={() => setLevel(4)} className='res-next-step'>تایید رزرو</Link>
      </div>
    </div>
  )
}
