import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Col, Container, Row } from 'react-bootstrap'
import NavLinks from '../NavLinks/NavLinks'
import Advertise from '../Advertise/Advetise'
import SingleBlogCard from './SingleBlogCard'
import axiosClient from "../../axios-client";
import NotFound from '../NotFound/NotFound'; 

export default function SingleBlog() {
    const [loading, setLoading] = useState(false)
    const [SingleBlog, setSingleBlog] = useState([]);
    const apiKey = process.env.REACT_APP_API_KEY;
    let { slug } = useParams();
    const [Blogs, setBlogs] = useState([]);
    const [error, setError] = useState(null);
  
    const getBlogs = () => {
      setLoading(true)
      
      axiosClient.get('/BlogSlider')
        .then(({ data }) => {
          setLoading(false)
          setBlogs(data.data)
        })
        .catch(() => {
          setLoading(false)
        })
    }
    
    useEffect(() => {
      getBlogs();
        setLoading(true)
        axiosClient.get(`/singleBlog/${slug}`)
            .then(({ data }) => {
                setLoading(false)
                setSingleBlog(data.data)
            })
            .catch(err => {
                if (err.response && err.response.status === 404) {
                    setError('Product Category not found');
                } else {
                    console.error(err);
                }
            });
    }, [slug])
    if (error) {
        return <NotFound />; 
    }
    return (
        <Container>
            <NavLinks />
            <Row>
                <Col lg={9}>
                    <h5 className='interdues text-center mb-5 mt-3'>{SingleBlog.name}</h5>
                    <div>
                        <div>
                            <img className='img-fluid col-12' src={apiKey + SingleBlog.photo} alt={SingleBlog.name} />
                        </div>
                        <div className='mt-5' >
                            <div style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: SingleBlog.article }} />
                        </div>

                    </div>

                </Col>
                <Col lg={3}>
                    <div className='mt-3'>
                        <Advertise />
                    </div>
                    <div className='mt-3'>
                        <h5 className='interdues text-center mb-5'>اخرین مقالات</h5>

                        {Blogs.slice(0,3).map((blog, index) => (
                            <SingleBlogCard key={index} {...blog}  />
                        ))}

                    </div>
                </Col>
            </Row>
        </Container>
    )
}
