import reviewData from '../../Data/reviewData';
import { Col, Row, Dropdown } from 'react-bootstrap';
import { Rating } from '@mui/material';
import { FaRegHeart } from "react-icons/fa6";
import "./Review.css";
import { MdOutlineStarPurple500 } from "react-icons/md";
import { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import { useNavigate, useLocation } from 'react-router-dom';
import profile from "../../Image/person.png";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

export default function Review(productId) {
    const navigate = useNavigate();
    const [reat, setReat] = useState("");
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [comments, setComments] = useState([]);
    const location = useLocation();
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        if (isExpanded) {
            setAnswerComment({
                product_comment_id: panel,
                answer: '',
                answerName: ''
            });
        }
        setExpanded(isExpanded ? panel : false);
    };

    const [productComment, setProductComment] = useState({
        product_id: productId.productId,
        comment: '',
        name: '',
        rate: reat,
    });

    const [answerComment, setAnswerComment] = useState({
        product_comment_id: '',
        answer: '',
        answerName: '',
    });

    useEffect(() => {
        setProductComment((prev) => ({ ...prev, rate: reat }));
    }, [reat]);

    const onSubmit = (ev) => {
        ev.preventDefault();
        setLoading(true);
        axiosClient.post('/productComment', productComment)
            .then((data) => {
                navigate(location.pathname);
                resetForm();
            })
            .catch((err) => {
                const response = err.response;
                if (response && response.status === 422) {
                    setErrors(response.data.errors);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onAnswerSubmit = (ev) => {
        ev.preventDefault();
        setLoading(true);
        axiosClient.post('/productCommentAnswer', answerComment)
            .then((data) => {
                navigate(location.pathname);
                resetForm();
            })
            .catch((err) => {
                const response = err.response;
                if (response && response.status === 422) {
                    setErrors(response.data.errors);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const resetForm = () => {
        setProductComment({
            product_id: productId.productId,
            comment: '',
            name: '',
            rate: "",
        });
        setAnswerComment({
            product_comment_id: '',
            answer: '',
            answerName: '',
        });
    };

    useEffect(() => {
        setLoading(true);
        axiosClient.get(`/getProductComment/${productId.productId}`)
            .then(({ data }) => {
                setLoading(false);
                setComments(data.data);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [productId]);

    return (
        <div>
            {comments.map((comment, index) => (
                <div key={index} className='pt-4'>
                    <Row>
                        <Col xs={2} lg={1}>
                            <img style={{ maxWidth: "50px" }} src={profile} alt="" />
                        </Col>
                        <Col xs={10} lg={9}>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <div className='fs-5'>{comment.name}</div>
                                    <div className='fs-8' style={{ color: "gray" }}>{comment.date}</div>
                                </div>
                                <span>
                                    <Rating
                                        style={{ direction: "ltr" }}
                                        size='small'
                                        name="text-feedback"
                                        value={comment.rate}
                                        readOnly
                                        precision={0.5}
                                    />
                                </span>
                            </div>
                            <div>
                                <p className='mb-1' style={{ textAlign: "justify" }}>{comment.comment}</p>
                                <Accordion expanded={expanded === comment.id} onChange={handleChange(comment.id)}>
                                    <AccordionSummary
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <div>
                                            <span className='mx-2'>پاسخ</span>
                                            <span className='mx-2'><FaRegHeart /></span>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <form onSubmit={onAnswerSubmit}>
                                            <Row className=''>
                                                <Col xs={12} lg={12} className='mt-3'>
                                                    <textarea
                                                        className='input-review px-2 fs-7'
                                                        rows={5}
                                                        placeholder='پاسخ شما ...'
                                                        value={answerComment.answer}
                                                        onChange={ev => setAnswerComment({ ...answerComment, answer: ev.target.value })}
                                                    />
                                                    {errors?.answer?.length > 0 && errors.answer[0] && (
                                                        <span className='text-danger fs-7'>{errors.answer[0]}</span>
                                                    )}
                                                </Col>
                                                <Col xs={6} lg={9} className=''>
                                                    <input
                                                        className='input-review px-2 fs-7'
                                                        placeholder='نام و نام خانوادگی'
                                                        type="text"
                                                        value={answerComment.answerName}
                                                        onChange={ev => setAnswerComment({ ...answerComment, answerName: ev.target.value })}
                                                    />
                                                    <input
                                                        className='input-review px-2 fs-7'
                                                        type="text"
                                                        value={answerComment.product_comment_id}
                                                        hidden
                                                        onChange={ev => setAnswerComment({ ...answerComment, product_comment_id: ev.target.value })}
                                                    />
                                                    {errors?.answerName?.length > 0 && errors.answerName[0] && (
                                                        <span className='text-danger fs-7'>{errors.answerName[0]}</span>
                                                    )}
                                                </Col>
                                                <Col xs={12} lg={3} className='mt-3 mt-lg-0'>
                                                    <button className='review-send' disabled={loading}>
                                                        {loading ? "Submitting..." : "ثبت دیدگاه"}
                                                    </button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </Col>
                    </Row>
                    {comment.answers.map((answer, index) => (
                        <Row className='answer mt-5'>
                            <Col xs={2} lg={1}>
                                <img style={{ maxWidth: "40px" }} src={profile} alt="" />
                            </Col>
                            <Col xs={10} className='ms-lg-3' lg={10}>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <div className='fs-5'>{answer.answerName}</div>
                                        <div className='fs-8' style={{ color: "gray" }}>{answer.date}</div>
                                    </div>
                                </div>
                                <div>
                                    <p className='mb-1' style={{ textAlign: "justify" }}>{answer.answer}</p>
                                </div>
                            </Col>
                        </Row>
                    ))}

                </div>
            ))}

            <div className='mt-lg-5'>
                <form onSubmit={onSubmit}>
                    <Row className=''>
                        <Col xs={12} lg={10} className='mt-3'>
                            <textarea
                                className='input-review px-2 fs-7'
                                rows={5}
                                placeholder='دیدگاه شما ...'
                                value={productComment.comment}
                                onChange={ev => setProductComment({ ...productComment, comment: ev.target.value })}
                            />
                            {errors?.comment?.length > 0 && errors.comment[0] && (
                                <span className='text-danger fs-7'>{errors.comment[0]}</span>
                            )}
                        </Col>
                        <Col xs={6} lg={4} className=''>
                            <input
                                className='input-review px-2 fs-7'
                                placeholder='نام و نام خانوادگی'
                                type="text"
                                value={productComment.name}
                                onChange={ev => setProductComment({ ...productComment, name: ev.target.value })}
                            />
                            {errors?.name?.length > 0 && errors.name[0] && (
                                <span className='text-danger fs-7'>{errors.name[0]}</span>
                            )}
                        </Col>
                        <Col xs={6} lg={3} className=''>
                            <Dropdown>
                                <Dropdown.Toggle variant="" className='review-reating fs-7' id="dropdown-basic">
                                    امتیاز : <span className='mx-2'>{reat}</span>
                                    <MdOutlineStarPurple500 className='ms-5' style={{ color: "#faaf00", fontSize: "18px" }} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item className='text-center' onClick={() => setReat(1)}>
                                        <Rating
                                            style={{ direction: "ltr" }}
                                            size='small'
                                            name="text-feedback"
                                            value={1}
                                            readOnly
                                            precision={0.5}
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Item className='text-center' onClick={() => setReat(2)}>
                                        <Rating
                                            style={{ direction: "ltr" }}
                                            size='small'
                                            name="text-feedback"
                                            value={2}
                                            readOnly
                                            precision={0.5}
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Item className='text-center' onClick={() => setReat(3)}>
                                        <Rating
                                            style={{ direction: "ltr" }}
                                            size='small'
                                            name="text-feedback"
                                            value={3}
                                            readOnly
                                            precision={0.5}
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Item className='text-center' onClick={() => setReat(4)}>
                                        <Rating
                                            style={{ direction: "ltr" }}
                                            size='small'
                                            name="text-feedback"
                                            value={4}
                                            readOnly
                                            precision={0.5}
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Item className='text-center' onClick={() => setReat(5)}>
                                        <Rating
                                            style={{ direction: "ltr" }}
                                            size='small'
                                            name="text-feedback"
                                            value={5}
                                            readOnly
                                            precision={0.5}
                                        />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col xs={12} lg={3} className='mt-3 mt-lg-0'>
                            <button className='review-send' disabled={loading}>
                                {loading ? "Submitting..." : "ثبت دیدگاه"}
                            </button>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    );
}
