import { useEffect, useState } from "react";
import NavLinks from '../NavLinks/NavLinks'
import { Select, MenuItem } from '@mui/material'
import { Container, Row, Col } from 'react-bootstrap';
import Logo from "./../../Image/logo.png"
import "./Blogs.css"
import arrows from "./../../Image/rows.png"
import { Link, useLocation } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import useMediaQuery from '@mui/material/useMediaQuery';
import axiosClient from "../../axios-client";


export default function Blogs() {
    const apiKey = process.env.REACT_APP_API_KEY;
    const [loading, setLoading] = useState(false);
    const [Blogs, setBlogs] = useState([]);
    const [CategoryBlogs, setCategoryBlogs] = useState([]);
    const [sort, setSort] = useState(4);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const handleChange = (event) => {
        setSort(event.target.value);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    useEffect(() => {
        getBlogs(currentPage);
    }, [sort, currentPage])

    useEffect(() => {
        getCategoryBlogs();
        document.title = ' خدمات مجموعه آپگریدر - فروشگاه آپگریدر';
    }, [])
    const getBlogs = (page) => {
        setLoading(true)
        axiosClient.get('/getBlogs/' + sort + `/?page=${page}`)
            .then(({ data }) => {
                setLoading(false)
                setBlogs(data.data)
                setCurrentPage(data.current_page || data.meta?.current_page || 1);
                setLastPage(data.last_page || data.meta?.last_page || 1);
            })
            .catch(() => {
                setLoading(false)
            })
    }
    const getCategoryBlogs = () => {
        axiosClient.get('/getCategoryBlogs/')
            .then(({ data }) => {
                setCategoryBlogs(data.data)
            })

    }




    const isMobile = useMediaQuery('(max-width:600px)');

    const link = [{
        name: 'خدمات مجموعه',
        link: '/category/خدمات-مجموعه-آپگریدر/',
    }];
    return (
        <>
            <Container>
                <NavLinks {...link} />
                <div className='d-flex justify-content-between filters-box mt-3 mt-lg-5'>
                    <h2 className='m-0'>خدمات مجموعه</h2>
                    <div className='d-flex'>
                        <Select
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                            className='sort-select'
                            defaultValue={4}
                        >

                            <MenuItem value={-1}>همه مقالات</MenuItem>
                            {CategoryBlogs.map((blog, index) => (
                                <MenuItem key={index} value={blog.id}>{blog.name}</MenuItem>
                            ))}
                        </Select>

                    </div>
                </div>
                <Row className='mt-3 mb-5 gy-3 gy-lg-5 gx-4'>
                    {Blogs.map((blog, index) => (
                        <Col lg={6} className='px-4' key={index}>
                            <div className='box-card-blog-border'>
                                <Row className='box-card-blog p-2 p-lg-4'>
                                    <Col xs={7}>
                                        <div className='card-blog-title'>
                                            {blog.name}
                                        </div>
                                        <div>
                                            <img className='img-fluid mt-3' style={{ borderRadius: "6px" }} src={apiKey + blog.photo} alt={blog.name} />
                                        </div>
                                    </Col>
                                    <Col xs={5}>
                                        <div className='text-end'>
                                            <span className=''><img src={Logo} className='img-fluid img-blog-card' alt="logo" /></span>
                                        </div>
                                        <div className='my-1 my-lg-3'>
                                            <p className='card-blog-content mb-0'>
                                                {blog.summary}
                                            </p>
                                        </div>
                                        <div className='text-end pe-3'>
                                            <span style={{ cursor: "pointer" }}>
                                                <Link to={'/' + blog.slug + '/'}>                                                        ادامه
                                                    <img src={arrows} className='img-fluid card-blog-arrow' alt="" />
                                                </Link>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    ))}
                </Row >
                <div className='pagination-container'>
                    <Pagination
                        className="pagination my-5"
                        count={lastPage} // Total number of pages
                        page={currentPage} // Current active page
                        onChange={(_, page) => handlePageChange(page)} // Page change handler
                        siblingCount={isMobile ? 1 : 2} // Number of items in the middle
                        boundaryCount={1} // Number of items at the start and end
                        renderItem={(item) => (
                            <PaginationItem
                                className="custom-pagination-item"
                                slots={{ previous: FiArrowRight, next: FiArrowLeft }} // Custom icons for previous and next
                                {...item}
                            />
                        )}
                    />
                </div>
            </Container >
        </>
    )
}
