import axios from 'axios';
const baseURL = `${process.env.REACT_APP_API_KEY}api`;


const axiosClient = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
})

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('CustomerToken');
  config.headers.Authorization = `Bearer ${token}`
  return config;
})

axiosClient.interceptors.response.use((response) => {
  return response
}, (error) => {
  const {response} = error;
  if (response.status === 401) {
    localStorage.removeItem('CustomerToken')
    window.location.reload();
  } else if (response.status === 404) {
    //Show not found
  }

  throw error;
})

export default axiosClient
