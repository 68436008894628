import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './BlogSlider.css';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';
import axiosClient from "../../../../axios-client";
export default function BlogSlider() {
    const apiKey = process.env.REACT_APP_API_KEY;
    const [loading, setLoading] = useState(false);
    const [Blogs, setBlogs] = useState([]);
  
    useEffect(() => {
      getBlogs();
    }, [])
  
    const getBlogs = () => {
      setLoading(true)
      axiosClient.get('/BlogSlider')
        .then(({ data }) => {
          setLoading(false)
          setBlogs(data.data)
        })
        .catch(() => {
          setLoading(false)
        })
    }
    


    return (
        <>
            <Swiper
                loop={true}
                Navigation={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    350: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                }}
                modules={[Pagination, Navigation, Autoplay]}
                className="mySwiper blog-slider-home"
            >
                {Blogs.map((blog, index) => (
                    <SwiperSlide key={index}>
                        <Link to={`${blog.slug}`}>
                            <div className='bg-blog blog-home-slider' style={{backgroundImage: `url(${apiKey +blog.blog_category_id.photo})` }}>
                                <div>
                                    <img src={apiKey + blog.photo} style={{ borderRadius: "15px" }} alt={blog.name} />
                                </div>
                                <div className='my-2 fs-6 fs-lg-4 mx-2 text-start product-title-cart'>{blog.name}</div>
                                <div className='footer-blog-card mb-3'>
                                    <span className='fs-7'>{blog.blog_category_id.name}</span>
                                    <span className='fs-7'>{blog.published_date}</span>
                                </div>
                            </div>
                        </Link>

                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}
