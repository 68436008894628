// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { TfiShoppingCart } from "react-icons/tfi";
import SliderNavBtns from './sliderNavBtns';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import './NewestProducts.css';

import { Navigation, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { useEffect, useMemo, useState } from "react";
import axiosClient from "../../../../axios-client";
import CardProduct from '../CardProduct';



export default function App() {
    const apiKey = process.env.REACT_APP_API_KEY;
    const [loading, setLoading] = useState(false);
    const [newProduct, setNewProduct] = useState([])

    useEffect(() => {
        getNewProduct();
    }, [])

    const getNewProduct = () => {
        setLoading(true)
        axiosClient.get('/getNewProduct')
            .then(({ data }) => {
                setLoading(false)
                setNewProduct(data.data)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    return (
        <>

            <Swiper
                Navigation={true}
                loop={true}
                breakpoints={{
                    350: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                }}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >

                {newProduct.map((newProduct, index) => (
                    <SwiperSlide key={index}>
                        <CardProduct {...newProduct} />
                    </SwiperSlide>
                ))}
                <SliderNavBtns />
            </Swiper>

        </>
    );
}


